<template>
  <div>
    <div class="user">
      <a-button type="primary" @click="getAccount">获取登陆账号和密码</a-button>
      <!-- <p style="margin-left: 20px;color:red;">注意：登陆时请选择企业账户登陆</p> -->
    </div>
    <a-modal v-model="visible" title="获取登陆账号和密码" @ok="handleOk">
      <p>用户名：shwf2023</p>
      <p>密码：strong</p>

      <p>访问链接：<a href="https://www.wanfangdata.com.cn" target="_blank">https://www.wanfangdata.com.cn</a></p>

      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
    </a-modal>
    <div @click="getAccount"><img src="@/assets/wf.png" alt="" style="width: 100%; height: 100%;" /></div>
  </div>
</template>

<script>
import { addResourcesUse} from "@/httpsAPI/resources/common";

export default {
  data () {
    return {
      visible: false,
    };
  },
  created () {
    addResourcesUse({ type: 7, content: '万方数据统计' }).then(res => { })
  },
  methods: {
    getAccount () {
      this.visible = true;
    },
    handleOk (e) {
      this.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  height: 45px;
  p {
    display: inline-block;
  }
}
</style>>

